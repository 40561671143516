.avatarTestmonial {
  object-fit: cover !important;
  width: 70px;
  height: 70px;
  border-radius: 35px;
}

.avatarTestmonialContainer {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 45px;
  margin-bottom: 25px;
}
